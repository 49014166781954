/* eslint-disable react/require-default-props */
import { css } from '@emotion/react';

import { gray2, gray5, primary, success } from 'styles/global_defaults/colors';
import { ActivityProgress } from 'redux/schemas/models/activity';

type Props = {
  icon: string,
  status?: ActivityProgress,
  statusesIcons?: Partial<Record<ActivityProgress, string>>,
};

/**
 * This component is part of lecture component activities UI, it is meant to
 * display the lecture component activity status icon.
 * This component currently has default status values which are:
 * "none" => Only displays the activity icon (for activities without any
 * progress or completion).
 * "in_progress" => Displays the same activity icon surrounded by a circle
 * progress (Common pattern in activities that can be in progress).
 * "done" => Displays a green circle with a check inside of it, indicating user
 * that activity has been completed.
 * NOTE: This component allows you to pass statusesIcons prop in case you want
 * to have a different icon with in a certain status (see story example).
 */
const ActivityStatusComponent = (props: Props) => {
  const {
    icon,
    status = 'not_started',
    statusesIcons = {},
  } = props;

  const getProgressStyles = () => {
    switch (status) {
      case 'completed':
        return css`
          border-color: ${success};
        `;
      case 'in_progress':
        return css`
          border-width: 2px;
          transform: rotate(-45deg);
          border-color: ${gray5} ${primary} ${primary} ${gray5};
        `;
      default:
        return css``;
    }
  };

  const getProgressIconStyles = () => {
    const common = css`
      color: ${primary};
    `;

    switch (status) {
      case 'completed':
        return css`
          color: ${success};
        `;
      case 'missed':
        return css`
          color: ${gray2};
        `;
      case 'in_progress':
        return css`
          ${common};
          transform: rotate(45deg);
        `;
      default:
        return common;
    }
  };

  const styles = css`
    padding: 11px;
    font-size: 24px;
    line-height: 19px;
    position: relative;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid transparent;
    ${getProgressStyles()};

    .status-icon-icon {
      display: block;
      font-family: 'novoed-icons';
      ${getProgressIconStyles()};
    }
  `;

  const statusesIconsToUse = {
    not_started: icon,
    missed: icon,
    completed: 'check',
    in_progress: icon,
    ...statusesIcons,
  };

  return (
    <div css={styles}>
      <i className={`status-icon-icon icon-${statusesIconsToUse[status]}`} aria-hidden='true' />
    </div>
  );
};

export default ActivityStatusComponent;
